<template>
  <v-toolbar style="max-height: 40px" flat color="transparent">
    <!-- Start burger menu -->
    <img
      v-if="smUp"
      class="burgerIcon"
      @click="toggleMini()"
      src="/static/menu_burger_icon.svg"
      alt="burger_menu"
    />
    <!-- Option for mobile devices -->
    <core-menu :items="arrItems" v-else></core-menu>
    <!-- End option for mobile devices-->
    <!-- End burger menu -->
    <!-- Push content to right -->
    <v-spacer></v-spacer>
    <!-- Push content to left -->
    <!-- Start admin profile -->
    <div class="adminUserWrapper">
      <div>
        <!-- Start user's name -->
        <p class="adminName">
          {{ formattedUsername }}
        </p>
        <!-- End user's name -->
      </div>
      <!-- Start user's image -->
      <!-- End user's image -->
    </div>
    <!-- End admin profile -->
  </v-toolbar>
</template>
<script>
export default {
  components: {
    // import mobile menu option
    CoreMenu: () => import("../core/MobileMenu"),
  },
  methods: {
    // toggle menu state
    toggleMenu() {
      this.$store.commit("togglebMobileMenu");
    },
    // toggle mini state
    toggleMini() {
      this.$store.commit("togglebMiniVariant");
    },
  },
  data: () => ({
    // store user's name
    username: "Usuario",
    // store user's role
    userRole: "Administrador",
  }),
  computed: {
    // import options list (same as side menu)
    arrItems() {
      return texts.spanish.admin.sideMenu.items;
    },
    smUp() {
      // return this.$vuetify.breakpoint.smAndUp;
      return this.$vuetify.breakpoint.lgAndUp;
    },
    // return user name reduced
    formattedUsername() {
      // return this.smUp
      //   ? localStorage.getItem("mail").substring(0, 20)
      //   : this.username.substring(0, 8);
      return localStorage.getItem("mail")
    },
  },
};
</script>
<style 
  scoped>
.burgerIcon {
  cursor: pointer;
  width: 30px;
}
.adminUserWrapper {
  justify-content: end;
  margin-top: 20px;
  width:50%;
  display: flex;
}
.adminName {
  margin-top: 10px;
  margin-right: 20px;
  line-height: 14px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: black;
}
.userRole {
  text-align: right;
  margin-right: 20px;
  margin-top: -13px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #eaa608;
}
</style>
<style>
.v-toolbar__content {
  border-bottom-style: solid;
  border-bottom-color: #ffce00;
  border-bottom-width: 2px;
}
</style>